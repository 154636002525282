<template>
  <div
    v-if="categories.length > 0 && $store.state.isBlog === 1"
    class="container-fluid theme__sub-bg"
  >
    <div class="row">
      <nav class="column full">
        <ol class="iv2-category-nav">
          <li
            v-for="category in categories"
            :key="category.id"
            class="iv2-category-nav-item"
          >
            <router-link
              :to="{
                name: 'blog',
                query: { category_id: category.id },
              }"
              class="iv2-category-nav-item__link"
            >
              {{ category.name }}
            </router-link>
          </li>
        </ol>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    categories: Array,
  },

  methods: {
    handleScroll() {
      this.scrollY = window.scrollY;
    },

    touchHandler() {
      this.activeMenu = !this.activeMenu;

      if (this.activeMenu == true) {
        const el = document.body;
        el.classList.add("noscroll");
        setTimeout(() => {
          this.menuAnimation = "display";
        }, 300);
      } else {
        const el = document.body;
        el.classList.remove("noscroll");
        this.menuAnimation = "";
      }
    },

    closeMenu() {
      this.activeMenu = false;
      this.menuAnimation = "";
      const el = document.body;
      el.classList.remove("noscroll");
    },
  },
};
</script>

<style lang="scss">
.iv2-category-nav {
  list-style-type: none;
  margin: 0;
  padding: 70px 0;

  .iv2-category-nav-item {
    padding-right: 15px;
    display: inline-block;

    &::after {
      content: "／";
    }

    &:last-child {
      &::after {
        content: "";
      }
    }

    .iv2-category-nav-item__link {
      padding-right: 15px;
    }
  }
}

/* SP Only */
@media (max-width: 767px) {
  .iv2-category-nav-item {
    font-size: 12px;
  }
}
</style>
