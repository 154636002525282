<template>
  <section v-if="$store.state.isBlog === 1" class="theme__sub-bg">
    <div class="container-fluid">
      <div class="row clearfix">
        <div class="column full">
          <BaseTagList :transform="true" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BaseTagList from "@/components/items/BaseTagList.vue";

export default {
  components: {
    BaseTagList,
  },

  props: {
    items: Object,
  },
};
</script>

<style lang="scss"></style>
