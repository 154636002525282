<template>
  <div>
    <article class="iv2-article" v-if="pageActive">
      <div v-if="indexOpenType === 1">
        <BaseBreadcrumb :items="breadcrumb" />

        <!-- 記事一覧 -->
        <LayoutList
          :blog="blog"
          :items="items"
          :categories="categories"
          :tags="tags"
          :archives="archives"
          :explanation="explanation"
          :keywords="params.keywords"
          @change-category="changeCategory"
          @change-tag="changeTag"
          @select-category="selectCategory"
          @select-tag="selectTag"
          @select-archive="selectArchive"
        />

        <!-- ページネーション -->
        <BasePagination
          v-if="blog.length > 0"
          :last-page="lastPage"
          :sort-page="params.page"
          @pagination="pagination"
        />
      </div>

      <Error404 v-else-if="indexOpenType === 0" />
    </article>

    <div v-if="loading">
      <BaseLoading />
    </div>
  </div>
</template>

<script>
import BaseBreadcrumb from "@/components/items/BaseBreadcrumb.vue";
import LayoutList from "./components/LayoutList.vue";
import BaseLoading from "@/components/items/BaseLoading.vue";
import BasePagination from "@/components/items/BasePagination.vue";
import Error404 from "@/components/systems/Page404.vue";
import Meta from "@/mixins/MetaSettable";

export default {
  components: {
    BaseBreadcrumb,
    LayoutList,
    BaseLoading,
    BasePagination,
    Error404,
  },

  mixins: [Meta],

  data() {
    return {
      blog: [],
      items: {
        title: "",
        subtitle: "",
      },
      breadcrumb: [
        {
          title: "",
          segment_name: "blog",
        },
      ],
      categories: [],
      tags: [],
      archives: [],
      lastPage: 1,
      explanation: "",
      indexOpenType: "",
      pageActive: false,
      loading: true,
      params: {
        type: "blog",
        limit: 24,
        page: 1,
        category_id: 0,
        year: 0,
        keywords: "",
        tag: [],
      },
      tagSetting: {},
    };
  },

  watch: {
    $route(to, from) {
      if (to.query !== from.query) {
        this.getNews();
      }
    },
  },

  created() {
    const newsRepository = this.$repository.get("news");
    const self = this;

    const tags = newsRepository.tag(this.tagSetting);
    tags.then((result) => {
      self.tags = result.data.response;
    });

    const categories = newsRepository.categories(this.params.type);
    categories
      .then((result) => {
        self.categories = result.data.response.categories;
      })
      .finally(() => {
        self.getNews();
      });

    let year = 2020;
    let nowDate = new Date().getFullYear();
    let tmp = nowDate - year + 1;
    for (let i = 0; i < tmp; i++) {
      this.archives.push(nowDate - i);
    }
  },

  methods: {
    getNews() {
      this.params.tag = [];
      this.$route.query.page
        ? (this.params.page = Number(this.$route.query.page))
        : (this.params.page = 1);
      this.$route.query.category_id
        ? (this.params.category_id = Number(this.$route.query.category_id))
        : (this.params.category_id = 0);
      this.$route.query.keywords
        ? (this.params.keywords = this.$route.query.keywords)
        : (this.params.keywords = "");
      this.$route.query.tag_id
        ? this.params.tag.push(this.$route.query.tag_id)
        : (this.params.tag = null);

      const blogRepository = this.$repository.get("news");
      const blog = blogRepository.index(this.params);
      const self = this;
      blog
        .then((result) => {
          const res = result.data.response;
          self.blog = res.data;
          if (self.$route.query.category_id) {
            var target = self.categories.filter(
              (e) => e.id == self.$route.query.category_id
            );
            self.breadcrumb[0].title = target[0].name;
          } else {
            self.breadcrumb[0].title = res.blog_title;
          }
          self.items.title = res.blog_title;
          self.items.subtitle = res.blog_subtitle;
          self.lastPage = res.last_page;
          self.explanation = res.blog_explanation;
          if (self.$route.query.mode == "preview") {
            self.indexOpenType = 1;
          } else {
            self.indexOpenType = res.blog_open_type;
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          self.loading = false;
          self.pageActive = true;
          window.scrollTo({
            top: 0,
          });
        });
    },

    pagination(pageNum) {
      this.loading = true;
      if (pageNum >= 1 && pageNum <= this.lastPage) {
        this.$router.push({
          query: Object.assign({}, this.$route.query, { page: pageNum }),
        });
      }
    },

    changeCategory(id) {
      this.loading = true;
      this.params.page = 1;
      if (id > 0) {
        this.$router.push({
          query: { category_id: id },
        });
      } else {
        this.$router.push({ name: "blog" });
      }
    },

    changeTag(id) {
      this.loading = true;
      this.params.page = 1;
      this.$router.push({
        query: { tag_id: id },
      });
    },

    selectCategory(event) {
      this.loading = true;
      if (event.target.value > 0) {
        this.$router.push({
          query: { category_id: event.target.value },
        });
      } else {
        this.$router.push({ name: "blog" });
      }
      this.params.page = 1;
    },

    selectTag(event) {
      this.loading = true;
      this.$router.push({
        query: { tag_id: event.target.value },
      });
      this.params.page = 1;
    },

    selectArchive(event) {
      this.loading = true;
      this.params.page = 1;
      this.params.year = event.target.value;
    },
  },
};
</script>

<style lang="scss"></style>
