var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[(_vm.first.length == 0 && _vm.thereAfter.length == 0)?_c('div',{staticClass:"iv2-ranking__null row clearfix"},[_c('div',{staticClass:"column full"},[_c('div',{staticStyle:{"padding":"2rem 0"}},[_c('h4',{staticClass:"size-24 text-center font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$store.state.publicLang.news.null_title)+" ")]),_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$store.state.publicLang.news.null_summary)+" ")])]),_c('div',{staticClass:"spacer height-60"})])]):_c('div',{staticClass:"row clearfix"},[_c('div',{staticClass:"col-12 mb-5 iv2-ranking-first"},[_c('router-link',{staticClass:"iv2-ranking-first__link",attrs:{"to":{
          name: 'blogDetail',
          params: { id: _vm.first.id },
          query: Object.assign({}, _vm.$route.query),
        }}},[_c('div',{staticClass:"iv2-ranking-first__card clearfix"},[_c('div',{staticClass:"iv2-ranking-after__rank"},[_vm._v(" "+_vm._s(_vm.first.rankNum)+" ")]),_c('div',{staticClass:"iv2-ranking-first__card-thumbnail float-left fit-img"},[_c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":_vm.first.thumbnail + _vm.$store.state.publicLang.general.image_webp}}),_c('img',{staticClass:"m-0",attrs:{"src":_vm.first.thumbnail,"alt":_vm.first.title}})])]),_c('div',{staticClass:"iv2-ranking-first__card-body float-right"},[_c('div',{staticClass:"mb-2 mb-md-4"},[_c('span',{staticClass:"iv2-ranking__label iv2-news__badge"},[_vm._v(_vm._s(_vm.first.category_name))])]),_c('div',{staticClass:"iv2-ranking-first__card-text"},[_vm._v(" "+_vm._s(_vm.first.title)+" ")]),_c('div',{staticClass:"iv2-ranking-first__card-footer row"},[(_vm.first.opened_at)?_c('div',{staticClass:"col-6 text-left"},[_vm._v(" "+_vm._s(_vm.first.opened_date)+" ")]):_vm._e(),(_vm.first.pageview_number && _vm.first.pageview_number > 0)?_c('div',{staticClass:"iv2-ranking-after__card-pageview col-6 text-right"},[_vm._v(" "+_vm._s(_vm.first.pageview_number.toLocaleString())+" "+_vm._s(_vm.$store.state.publicLang.blog.pageview)+" ")]):_vm._e()])])])])],1),_vm._l((_vm.thereAfter),function(item){return _c('div',{key:item.id,staticClass:"mb-5",class:item.rankNum < 4
          ? 'iv2-ranking-after6 col-lg-6 col-md-4 col-6'
          : 'iv2-ranking-after3 col-md-4 col-6'},[_c('router-link',{staticClass:"iv2-ranking-after__link",attrs:{"to":{
          name: 'blogDetail',
          params: { id: item.id },
          query: Object.assign({}, _vm.$route.query),
        }}},[_c('div',{staticClass:"iv2-ranking-after__card"},[_c('div',{staticClass:"iv2-ranking-after__rank",class:_vm.rankClass(item.rankNum)},[_vm._v(" "+_vm._s(item.rankNum)+" ")]),_c('div',{staticClass:"iv2-ranking-after__card-thumbnail fit-img"},[_c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":item.thumbnail + _vm.$store.state.publicLang.general.image_webp}}),_c('img',{staticClass:"m-0",attrs:{"src":item.thumbnail,"alt":item.title}})])]),_c('div',{staticClass:"iv2-ranking__card-body"},[_c('div',{staticClass:"mb-2 mb-xl-4"},[_c('span',{staticClass:"iv2-ranking__label iv2-news__badge",class:item.category_name},[_vm._v(_vm._s(item.category_name))])]),_c('div',{staticClass:"iv2-ranking-after__card-text"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"iv2-ranking-after__card-footer row"},[(item.opened_at)?_c('div',{staticClass:"col-lg-7 p-0 text-left"},[_vm._v(" "+_vm._s(item.opened_date)+" ")]):_vm._e(),(item.pageview_number && item.pageview_number > 0)?_c('div',{staticClass:"iv2-ranking-after__card-pageview col-lg-5 p-0 text-lg-right"},[_vm._v(" "+_vm._s(item.pageview_number.toLocaleString())+" "+_vm._s(_vm.$store.state.publicLang.blog.pageview)+" ")]):_vm._e()])])])])],1)}),_vm._m(0)],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column full"},[_c('div',{staticClass:"spacer height-60"})])}]

export { render, staticRenderFns }