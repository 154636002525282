<template>
  <div>
    <article class="iv2-article" v-if="pageActive">
      <div v-if="indexOpenType === 1">
        <BaseTitle :items="items" />

        <div class="container-fluid mb-4">
          <div class="row clearfix">
            <div class="col-12">
              <BaseBreadcrumb :items="breadcrumb" />
            </div>
          </div>
        </div>

        <!-- 記事一覧 -->
        <LayoutList :first="first" :there-after="thereAfter" />
      </div>

      <Error404 v-else-if="indexOpenType === 0" />
    </article>

    <div v-if="loading">
      <BaseLoading />
    </div>
  </div>
</template>

<script>
import BaseBreadcrumb from "@/components/items/BaseBreadcrumb.vue";
import BaseTitle from "@/components/items/BaseTitle.vue";
import LayoutList from "./components/LayoutList.vue";
import BaseLoading from "@/components/items/BaseLoading.vue";
import Error404 from "@/components/systems/Page404.vue";
import Meta from "@/mixins/MetaSettable";

export default {
  components: {
    BaseTitle,
    BaseBreadcrumb,
    LayoutList,
    BaseLoading,
    Error404,
  },

  mixins: [Meta],

  data() {
    return {
      first: [],
      thereAfter: [],
      items: {
        title: "",
        subtitle: "",
        header_type: "text_only",
      },
      breadcrumb: [],
      categories: [],
      archives: [],
      lastPage: 1,
      explanation: "",
      indexOpenType: "",
      pageActive: false,
      loading: true,
      params: {
        type: "blog",
        limit: 24,
        page: 1,
        category_id: 0,
        year: 0,
      },
    };
  },

  watch: {
    $route(to, from) {
      if (to.query !== from.query) {
        this.getNews();
      }
    },
  },

  created() {
    this.getNews();

    this.items.title = this.$store.state.themeLang.general.pageview_rank_title;
    this.items.subtitle =
      this.$store.state.themeLang.general.pageview_rank_subtitle;

    this.breadcrumb[0] = {
      title: this.$store.state.themeLang.general.pageview_rank_title,
      segment_name: "ranking",
    };
  },

  methods: {
    getNews() {
      const blogRepository = this.$repository.get("news");
      const blog = blogRepository.rank(this.params);
      const self = this;

      blog
        .then((result) => {
          const res = result.data.response;

          if (res.archive && res.archive.length > 0) {
            // 1位を抽出
            self.first = res.archive[0];
            self.first.rankNum = 1;

            // 2位以降を抽出
            for (var i = 1; i <= 14; i++) {
              if (res.archive[i]) {
                self.thereAfter[i - 1] = res.archive[i];
                self.thereAfter[i - 1].rankNum = i + 1;
              }
            }

            if (self.$route.query.mode == "preview") {
              self.indexOpenType = 1;
            } else {
              self.indexOpenType = res.blog_open_type;
            }
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          self.loading = false;
          self.pageActive = true;
        });
    },
  },
};
</script>

<style lang="scss"></style>
