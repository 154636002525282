<template>
  <div
    v-if="blog.length > 0 && tags.length > 0"
    class="iv2-blog-connection container-fluid"
  >
    <div class="row clearfix">
      <div class="column full">
        <hr />
        <div class="spacer height-80"></div>
      </div>
      <div class="column full text-center theme__heading">
        <h4 class="iv2-top-pickup__title theme__heading-text">
          {{ $store.state.publicLang.blog.connection_article }}
        </h4>
      </div>
      <div class="column full">
        <div class="spacer height-40"></div>
      </div>
      <div
        v-for="item in blog"
        :key="item.id"
        class="iv2-blog-connection__item col-6 col-md-4 col-xl-3"
      >
        <router-link
          :to="{
            name: 'blogDetail',
            params: { id: item.id },
            query: Object.assign({}, $route.query),
          }"
          class="iv2-blog-connection__link"
        >
          <div class="iv2-blog-connection__img fit-img">
            <picture>
              <source
                type="image/webp"
                :srcset="
                  item.thumbnail + $store.state.publicLang.general.image_webp
                "
              />
              <img
                class="m-0"
                :src="item.thumbnail"
                :alt="
                  '「' + item.title + '」' + $store.state.publicLang.news.alt
                "
              />
            </picture>
          </div>
          <p class="iv2-blog-connection__title">{{ item.title }}</p>
        </router-link>
      </div>
      <div class="column full">
        <div class="spacer height-80"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: Number,
    tags: Array,
  },

  data() {
    return {
      blog: [],
      params: {
        type: "blog",
        limit: 8,
        tag: [],
      },
    };
  },

  watch: {
    id() {
      this.getConnection();
    },
  },

  mounted() {
    this.getConnection();
    this.gridHeight();
  },

  updated() {
    this.gridHeight();
  },

  methods: {
    getConnection() {
      this.params.tag = this.tags;
      const blogRepository = this.$repository.get("news");
      const blog = blogRepository.index(this.params);
      const self = this;
      blog
        .then((result) => {
          const res = result.data.response;
          self.blog = res.data;

          const idList = self.blog.map((obj) => {
            return obj.id;
          });

          const index = idList.indexOf(self.id);
          if (index !== -1) {
            self.blog.splice(index, 1);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    gridHeight() {
      let title = document.getElementsByClassName("iv2-blog-connection__title");
      let titleMaxHeight = 0;
      let titleArray = new Array();

      Array.prototype.forEach.call(title, (el) => {
        el.style.height = "";
        titleArray.push(el.clientHeight);
      });

      titleMaxHeight = Math.max.apply(null, titleArray);

      Array.prototype.forEach.call(title, (el) => {
        el.style.height = titleMaxHeight + "px";
      });
    },
  },
};
</script>

<style lang="scss">
.iv2-blog-connection {
  .iv2-blog-connection__item {
    .iv2-blog-connection__link {
      &:hover {
        text-decoration: none;
      }

      .iv2-blog-connection__img {
        height: 180px;
        border-radius: 5px;
      }

      .iv2-blog-connection__title {
        font-weight: bold;
        margin-bottom: 1.75rem;
        line-height: 1.4;
      }
    }
  }
}

/* TABLET Only */
@media (min-width: 768px) and (max-width: 1199px) {
  .iv2-blog-connection {
    .iv2-blog-connection__item {
      .iv2-blog-connection__img {
        height: 150px;
      }
    }
  }
}

/* SP Only */
@media (max-width: 767px) {
  .iv2-blog-connection {
    .iv2-blog-connection__item {
      padding-right: 5px;
      padding-left: 5px;

      .iv2-blog-connection__link {
        .iv2-blog-connection__title {
          font-size: 14px;
        }
      }

      .iv2-blog-connection__img {
        height: 130px;
      }
    }
  }
}
</style>
