<template>
  <div
    v-if="tags.length > 0"
    :class="{ 'position-relative': transform }"
    class="iv2-tags theme__main-bg"
  >
    <h4 :class="{ 'transform-title': transform }" class="text-center mt-0">
      <span class="iv2-tags__title theme__tags-title">{{
        $store.state.themeLang.general.search_tag
      }}</span>
    </h4>
    <div class="iv2-tags__block text-center">
      <router-link
        v-for="tag in tags"
        :key="tag.id"
        class="iv2-tags__label iv2-news__badge"
        :to="{
          name: 'blog',
          query: { tag_id: tag.id },
        }"
        ># {{ tag.name }}</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    transform: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      tags: [],
      params: {
        is_recommend: 1,
      },
    };
  },

  mounted() {
    const blogRepository = this.$repository.get("news");
    const blog = blogRepository.tag(this.params);
    const self = this;
    blog
      .then((result) => {
        self.tags = result.data.response;
      })
      .catch((error) => {
        console.error(error);
      });
  },
};
</script>

<style lang="scss">
.iv2-tags {
  border-radius: 15px;
  padding: 30px;
  margin: 40px 0;

  .transform-title {
    position: absolute;
    top: -14px;
    left: 50%;
    transform: translateX(-50%);
    font-size: unset;
  }

  .iv2-tags__title {
    text-align: center;
    font-size: 21px;
    font-weight: bold;
    white-space: nowrap;
    position: relative;

    &::before {
      content: "";
      width: 100px;
      height: 1px;
      position: absolute;
      left: -150px;
      top: 50%;
      transform: translateY(-50%);
    }

    &::after {
      content: "";
      width: 100px;
      height: 1px;
      position: absolute;
      right: -150px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .iv2-tags__label {
    margin: 5px;

    &:hover {
      text-decoration: none;
    }
  }
}

/* SP Only */
@media (max-width: 767px) {
  .iv2-tags {
    .iv2-tags__label {
      padding: 0px 20px;
    }
    .iv2-tags__title {
      &::before,
      &::after {
        content: none;
      }
    }
  }
}
</style>
