<template>
  <div>
    <article class="iv2-article" v-if="pageActive">
      <HomeScroll />
      <!-- メインビジュアル -->
      <LayoutHero
        :items="items"
        :organization="organization"
        :bottom="bottom"
      />

      <!-- customize -->
      <!-- コンテンツエリア -->
      <LayoutPattern :items="items" />

      <!-- ピックアップ記事 -->
      <LayoutPickup :items="items" />

      <!-- ブログ -->
      <LayoutBlog :items="items" />

      <!-- ランキング記事 -->
      <LayoutRank :items="items" />

      <!-- ピックアップカテゴリ -->
      <LayoutCategory />

      <!-- ニュース -->
      <LayoutNews :items="items" :rss="rss" />

      <!-- 外部RSS -->
      <LayoutRss :rss="rss" />

      <!-- 記事検索 -->
      <LayoutSearch />

      <!-- おすすめタグ一覧 -->
      <LayoutTagList />

      <!-- customize -->
    </article>
    <div v-if="loading">
      <BaseLoading />
    </div>
  </div>
</template>

<script>
import HomeScroll from "./components/HomeScroll.vue";
import LayoutHero from "./components/LayoutHero.vue";
import LayoutSearch from "./components/LayoutSearch.vue";
import LayoutTagList from "./components/LayoutTagList.vue";
import LayoutPattern from "./components/LayoutPattern.vue";
import LayoutBlog from "./components/LayoutBlog.vue";
import LayoutRank from "./components/LayoutRank.vue";
import LayoutPickup from "./components/LayoutPickup.vue";
import LayoutCategory from "./components/LayoutCategory.vue";
import LayoutNews from "./components/LayoutNews.vue";
import LayoutRss from "./components/LayoutRss.vue";
import BaseLoading from "@/components/items/BaseLoading.vue";
import Meta from "@/mixins/MetaSettable";
import Hash from "@/mixins/AnchorLink";
import Preview from "@/mixins/Preview";

export default {
  components: {
    HomeScroll,
    LayoutHero,
    LayoutSearch,
    LayoutTagList,
    LayoutPattern,
    LayoutBlog,
    LayoutRank,
    LayoutPickup,
    LayoutCategory,
    LayoutNews,
    LayoutRss,
    BaseLoading,
  },

  mixins: [Meta, Hash, Preview],

  props: {
    organization: Object,
    rss: Object,
    bottom: Array,
  },

  data() {
    return {
      items: {},
      pageActive: false,
      loading: true,
    };
  },

  mounted() {
    const topRepository = this.$repository.get("topPage");
    const topPage = topRepository.index(
      this.$route.query.mode,
      this.$route.query.api_token
    );
    const self = this;
    topPage
      .then((result) => {
        self.items = result.data.response;
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        self.loading = false;
        self.pageActive = true;
      });
  },
};
</script>

<style lang="scss"></style>
